import { Layout, PrimaryButton, PrimaryButtonSimple, SEO } from "@/components";
import { GameLayout } from "@/layouts";
import React from "react";

const data = {
	teams: [
		{
			name: "Minería",
			participants: [
				{
					name: "arturo balbin",
				},
				{
					name: "alex salas",
				},
			],
			variables: [
				{
					name: "um",
					value: 100,
					upperLimit: 100,
					lowerLimit: 0,
				},
				{
					name: "ap",
					value: 50,
					lowerLimit: 0,
				},
			],
		},
		{
			name: "Global",
			variables: [
				{
					name: "um",
					value: 100,
					upperLimit: 100,
					lowerLimit: 0,
				},
				{
					name: "ap",
					value: 50,
					lowerLimit: 0,
				},
			],
		},
	],
};

const TemaManagementPage = () => (
	<GameLayout navbar={true}>
		<SEO title="Administración de Equipos" />
		<h1 className="text-2xl font-bold mb-4">Administrar equipos del juego</h1>
		{data.teams.map((team) => {
			return (
				<div className="border border-1 p-2 mb-2">
					<p className="font-bold text-xl">{team.name}</p>
					<p className="text-lg">Acciones de equipo</p>
					<div className="flex gap-x-2 mb-2">
						<PrimaryButtonSimple className="text-xs p-1">Inhabilitar equipo</PrimaryButtonSimple>
					</div>
					<div>
						<p className="text-lg">Participantes</p>
						<ul>
							{team.participants?.map((part) => {
								return (
									<li className="mb-4">
										<div className="flex flex-col">
											{part.name}
											<div className="flex gap-x-2">
												<PrimaryButtonSimple className="text-xs p-1">Enviar Contraseña</PrimaryButtonSimple>
												<PrimaryButtonSimple className="text-xs p-1">Transferir a equipo</PrimaryButtonSimple>
											</div>
										</div>
									</li>
								);
							})}
						</ul>
					</div>
					<div>
						<p className="text-lg">Variables</p>
						<ul>
							{team.variables.map((variable) => {
								return (
									<li className="mb-4">
										<div className="flex flex-col">
											{variable.name} {`->`} {variable.value}
											{variable.lowerLimit !== undefined && <span>L. I.: {variable.lowerLimit}</span>}
											{variable.upperLimit !== undefined && <span>L. S.: {variable.upperLimit}</span>}
										</div>
										<div className="flex gap-x-2">
											<PrimaryButtonSimple className="text-xs p-1">Editar Valores</PrimaryButtonSimple>
										</div>
									</li>
								);
							})}
						</ul>
					</div>
				</div>
			);
		})}
	</GameLayout>
);

export default TemaManagementPage;
